"use client";
import React from "react";
import Link from 'next/link'
import Counter from '@/components/counter'
import { useSession } from "next-auth/react"


const Home = () => {
  
  const { data: session, status } = useSession()

  if (status === "authenticated") {
    return <p>Signed in as {session.user.email}</p>
  }

  return (
    <>
      <Counter />
      <Link href="/subsection">subsection</Link>
      <a href="/api/auth/signin">Sign in</a>
    </>
  )
}




export default Home;
